<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container  mt-5">
            <div class="row">
                <div class="col-12 col-md-5 mx-auto menu-page ">
                    <div class="align-center mb-5">
                        <h2>Create Your Techless ID</h2>
                    </div>
                    <div>
                        <div>
                            <div>
                                <form autocomplete="on" [formGroup]="form" (ngSubmit)="createId()">

                                    <!-- <p class="text-muted">Sign In to your account</p> -->
                                    <div class="mb-3">
                                        <div class="col-12 mt-5 input-effect">
                                            <input class="effect mt-3" type="text" placeholder=""
                                                formControlName="username" id="username" autocomplete="email" />
                                            <label>Email</label>
                                            <span class="focus-border"></span>
                                        </div>
                                    </div>
                                    <div class="mb-4">
                                        <div class="col-12 mt-5 input-effect">
                                            <input class="effect mt-3" type="text" placeholder=""
                                                formControlName="firstname" autocomplete="given-name" />
                                            <label>First Name</label>
                                            <span class="focus-border"></span>
                                        </div>
                                    </div>

                                    <div class="mb-4">
                                        <div class="col-12 mt-5 input-effect">
                                            <input class="effect mt-3" type="text" placeholder=""
                                                formControlName="lastname" autocomplete="family-name" />
                                            <label>Last Name</label>
                                            <span class="focus-border"></span>
                                        </div>
                                    </div>
                                    <div class="mb-4">
                                        <div class="col-12 mt-5 input-effect">
                                            <input class="effect mt-3" type="password" placeholder=""
                                                formControlName="password" autocomplete="current-password" />
                                            <label>Password</label>
                                            <span class="focus-border"></span>
                                        </div>
                                    </div>
                                    <div class="mb-4">
                                        <div class="col-12 mt-5 input-effect">
                                            <input class="effect mt-3" type="password" placeholder=""
                                                formControlName="repeatPassword" autocomplete="new-password" />
                                            <label>Confirm Password</label>
                                            <span class="focus-border"></span>
                                        </div>
                                        <p class="help er-text" *ngIf="(notEqual &&      
                                        form.controls.password.dirty && 
                                        form.controls.repeatPassword.dirty) &&  form.value.repeatPassword">
                                            Passwords Do Not Match</p>
                                        <p class="help er-text"
                                            *ngIf="(form.controls.repeatPassword.touched||form.controls.repeatPassword.dirty) && !form.value.repeatPassword ">
                                            Required
                                        </p>
                                    </div>
                                    <div class="row pb-4">
                                        <div class="col-6">
                                            <button type="button" (click)="commonService.cancelForm(form)"
                                                class="btn btn-default btn-xl px-4 np-mobile" onfocus="blur()">
                                                Back
                                            </button>
                                        </div>
                                        <div class="col-6 align-right-mobile pr-5">
                                            <button type="submit" class="btn btn-default btn-xl px-4 np-mobile"
                                                onfocus="blur()">
                                                Create ID
                                            </button>
                                        </div>
                                        <div class="col-12 align-right-mobile">
                                            <p class="mt-3 mr-2" *ngIf="commonService.errorMessage">
                                                {{commonService.errorMessage}}
                                            </p>
                                            <p class="mt-3 mr-2" *ngIf="submitMsg">{{submitMsg}}</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>