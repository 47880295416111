<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container mt-5">
            <div class="row justify-content-center">
                <div class="col-5 mx-auto menu-page " style="top: 25vh;">
                    <h2>Reset Password</h2>
                    <div>
                        <div>
                            <div>
                                <form autocomplete="on" [formGroup]="form" (ngSubmit)="onUpdate()">

                                    <!-- <p class="text-muted">Sign In to your account</p> -->

                                    <div class="mb-4">


                                        <div class="col-12 mt-5 input-effect">
                                            <input class="effect mt-3" type="password" placeholder=""
                                                formControlName="newPassword" autocomplete="new-password" />
                                            <label>New Password</label>
                                            <span class="focus-border"></span>
                                        </div>
                                        <!-- <div class="input-group mb-3">
                                            <input [type]="newPassword" class="form-control" placeholder=""
                                                formControlName="newPassword" autocomplete="new-password" required />
                                            <div class="input-group-append">
                                                <span class="input-group-text" (click)="toggleType('newPassword')"> <i
                                                        class="fa fa-eye" *ngIf="newPassword=='password'"></i>
                                                    <i class="fa fa-eye-slash" *ngIf="newPassword=='text'"></i>
                                                </span>
                                            </div>
                                        </div> -->

                                    </div>


                                    <div class="mb-4">
                                        <div class="col-12 mt-5 input-effect">
                                            <input class="effect mt-3" type="password" placeholder=""
                                                formControlName="confirmNewPassword"
                                                autocomplete="confirm-new-password" />
                                            <label>Confirm New Password</label>
                                            <span class="focus-border"></span>
                                        </div>
                                        <!-- <div>
                                            <label>Confirm New Password</label>
                                        </div>
                                        <div class="input-group mb-3">

                                            <input [type]="confirmPassword" class="form-control" placeholder=""
                                                formControlName="confirmNewPassword" autocomplete="confirm-new-password"
                                                required />
                                            <div class="input-group-append">
                                                <span class="input-group-text" (click)="toggleType('confirmPassword')">
                                                    <i class="fa fa-eye" *ngIf="confirmPassword=='password'"></i>
                                                    <i class="fa fa-eye-slash" *ngIf="confirmPassword=='text'"></i>
                                                </span>
                                            </div>
                                        </div> -->

                                        <!-- <i class="fa fa-eye-slash"></i> -->


                                        <div class="col-12 error"
                                            *ngIf="(notEqual &&      
                                                        form.controls.newPassword.dirty && 
                                                        form.controls.confirmNewPassword.dirty) && (form.controls.confirmNewPassword.invalid && form.value.confirmNewPassword)">
                                            <p>Confirm new password is different from new password</p>
                                        </div>
                                    </div>



                                    <div class="mb-4">
                                        <!-- <div>
                                            <label>Verification Code</label>
                                        </div>
                                        <div class="input-group mb-3">

                                            <input type="text" class="form-control" placeholder=""
                                                formControlName="code" required />

                                        </div> -->
                                        <div class="col-12 mt-5 input-effect">
                                            <input class="effect mt-3" type="text" placeholder=""
                                                formControlName="code" />
                                            <label>Verification Code</label>
                                            <span class="focus-border"></span>
                                        </div>
                                        <p>Please check your e-mail from techless for verification code</p>
                                    </div>



                                    <div class="row">
                                        <div class="col-5 mx-auto">
                                            <button type="button" (click)="commonService.goBack()"
                                                class="btn btn-default btn-xl px-4" onfocus="blur()">
                                                Go Back
                                            </button>
                                        </div>
                                        <div class="col-5 mx-auto">
                                            <button (click)="onUpdate()" type="submit"
                                                class="btn btn-default btn-xl px-4" onfocus="blur()">
                                                Submit
                                            </button>
                                        </div>
                                        <div class="col-12 align-right-mobile no-padding">
                                            <p class="mt-3 mr-2" *ngIf="commonService.errorMessage">
                                                {{commonService.errorMessage}}</p>
                                            <p class="mt-3 mr-2" *ngIf="submitMsg">{{submitMsg}}</p>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>