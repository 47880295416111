<div class="app-body">
  <main class="d-flex justify-content-center">
    <div class="lookup-card">
      <h3>Check Your Email</h3>
      <p class="card-text" style="line-height: 150%;">An email is being sent to {{this.email}} with a temporary 6-digit
        passcode. Enter the code into the field below. Your code will expire in 15 minutes.</p>

      <form lass="mt-5" autocomplete="on" [formGroup]="form" (ngSubmit)="onCodeSubmit()">


        <div class="col-12 mt-3 input-effect">
          <input class="effect mt-3" type="text" placeholder="" formControlName="verificationCode"
            autocomplete="verificationCode" onkeyup="this.setAttribute('value', this.value);" value="" />
          <label>Verification Code</label>

          <span class="focus-border"></span>
        </div>

        <div class="row mt-3">
          <div class="col-12  mt-3">
            <button class="mr-2 back-button button" onfocus="blur()">Go Back</button>
            <button type="submit" class="submit-button button" onfocus="blur()">Submit</button>
          </div>
        </div>

        <div class="col-12 mt-3 pl-0 ">
          <p class="error-message">{{this.errorMessage}}</p>
          <br />
        </div>
      </form>

      <div class="mt-2">
        <p class="card-text">Didn’t get an email?&nbsp;<span class="txt-click-btn" (click)="resendCode()">Resend
            code.</span></p>
      </div>
    </div>
  </main>
</div>