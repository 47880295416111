<div class="dialog dialog-centered" style="background: #F8F6F2;" *ngIf="!isDeletedModel">
  <div class="dialog-content">
    <div class="dialog-header">
      {{ getImageSize(previewImageUrl.url) }}
      <img src="{{ previewImageUrl.url }}" alt="" width="100%" />
    </div>
    <div [class]="ResizeImage">
      <div class="my-d-flex margin-bottom-3">
        <div class="margin-right-auto">{{ previewImageUrl.name }}</div>
        <div class="image-date">
          {{ previewImageUrl.created_at | date: "MMM d, yyyy" }}
        </div>
      </div>
      <div id="dialog-actions" class="my-d-flex image-container">
        <button
          (click)="downloadUrl(previewImageUrl.url, previewImageUrl.name)"
          class="download margin-right-3"
        >
          Download
        </button>
        <!-- <button
          class="delete margin-right-auto button-decorator"
          mat-dialog-close
          mat-dialog-close="delete"
          (click)="onDelete()"
        >
          Delete
        </button> -->
        <button
          class="delete margin-right-auto button-decorator"
          (click)="openDialog()"
        >
          Delete
        </button>
        <button class="margin-right-3" (click)="prevPicture()">Previous</button>
        <button class="" (click)="nextPicture()">Next</button>
      </div>
    </div>
  </div>
</div>

<div class="dialog dialog-centered" style="background: #F8F6F2;" *ngIf="isDeletedModel">
  <div class="dialog-content delete-modal">
    <div class="dialog-body">
      <h6>Delete Media?</h6>
      <p>
        You are about to permanently delete this media from
        {{ commonService.personInfo.firstname }}’s phone and the Family Portal.
      </p>
      <p>Do you wish to continue?</p>
      <div id="dialog-actions" class="my-d-flex image-container">
        <button class="modal_btn" mat-dialog-close (click)="onDelete()">
          Delete
        </button>
        <button class="modal_btn" mat-dialog-close>Cancel</button>
      </div>
    </div>
  </div>
</div>
