import { ApiService } from 'src/app/services/api.service';
import { CommonService } from './../../services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {


    public form: FormGroup;
    public notEqual = false;
    public confirmPassword = 'password';
    public submitMsg = '';
    public newPassword = 'password';
    constructor(private fb: FormBuilder,
        public commonService: CommonService,
        private router: Router,
        private apiService: ApiService) {
        this.submitMsg = '';
    }

    ngOnInit() {
        this.form = this.fb.group({
            newPassword: ['', { validators: [Validators.required] }],
            confirmNewPassword: ['', { validators: [Validators.required] }],
            code: ['', { validators: [Validators.required], }
            ]
        });

    }

    ngOnDestroy() {
        this.commonService.errorMessage = '';
    }



    /**
     * @method onUpdate
     * @description this method used to update the password
     */
    public onUpdate() {
        this.submitMsg = '';
        this.checkValidatity();
        setTimeout(() => {

            if (this.form.valid) {
                const body = {
                    username: this.commonService.decryptData('password-username'),
                    password: this.form.value.newPassword,
                    code: this.form.value.code
                };
                this.apiService.httpPost('users/resetpassword', body).subscribe((response: any) => {
                    this.submitMsg = response.message;
                    this.router.navigate(['/login']);
                });
            } else {
                this.commonService.validateForm(this.form);
            }
        }, 500);
    }

    /**
     * @method toggleType
     * @param key: string
     * @description this method toggles the password type
     */
    public toggleType(key: string) {
        if (this[key] === 'password') {
            this[key] = 'text';
        } else {
            this[key] = 'password';
        }
    }

    /**
 * @method checkValidatity
 * @description this method checks for password validity
 */
    private checkValidatity() {
        if (!this.form.value.confirmNewPassword) {
            this.form.controls.confirmNewPassword.setErrors({ required: true });

        } else if (this.form.value.newPassword !== this.form.value.confirmNewPassword) {
            this.form.controls.confirmNewPassword.setErrors({ notEqual: true });
            this.notEqual = true;
        } else {
            this.notEqual = false;
            this.form.controls.confirmNewPassword.setErrors(null);
        }
    }

}
