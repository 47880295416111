<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-12 col-md-5">
          <div>
            <div>
              <div>
                <div class="align-center">
                  <img src="logo-lockup.png" class="logo-main" alt="Techless" />
                </div>
                <form
                  class="mt-5"
                  autocomplete="on"
                  [formGroup]="form"
                  (ngSubmit)="onLoginSubmit()"
                >
                  <div>
                    <h3 class="text-center" style="font-family: bookmania">
                      Family Portal
                    </h3>
                  </div>
                  <!-- <p class="text-muted">Sign In to your account</p> -->
                  <div class="col-12 p-0 mt-5 input-effect">
                    <input
                      class="login mt-3"
                      id="username"
                      type="text"
                      placeholder=""
                      formControlName="username"
                      autocomplete="email"
                    />
                    <label id="user-label">
                      <span class="">Username</span>
                    </label>
                    <span class="focus-border"></span>
                  </div>
                  <div class="col-12 p-0 mt-3 mb-4 input-effect">
                    <input
                      class="login mt-3"
                      type="password"
                      id="password"
                      placeholder=""
                      formControlName="password"
                      autocomplete="password"
                    />
                    <label id="password-label">
                      <span class="">Password</span>
                    </label>
                    <span class="focus-border"></span>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12 mt-3 text-center">
                      <button
                        type="submit"
                        class="button submit-button"
                        onfocus="blur()"
                        style="width: 100%; padding: 7px"
                      >
                        Sign In
                      </button>
                    </div>

                    <div class="col-12 mt-3 text-center">
                      <button
                        class="button new-account-button"
                        onfocus="blur()"
                        (click)="routeToTechless()"
                        style="width: 100%; padding: 7px"
                      >
                        Setup A New Account
                      </button>
                    </div>

                    <div class="col-12 mt-2 px-0 mx-auto login-error-container">
                      <p *ngIf="errorMessage !== null" class="login-error ml-0">
                        <strong>Try Again.</strong> {{ errorMessage }}
                      </p>
                    </div>

                    <div class="col-12 align-center pt-2">
                      <p>
                        <span
                          class="pointer link small"
                          [routerLink]="['/forgot-password']"
                          >Forgot Username or Password?</span
                        >
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
