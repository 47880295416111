<div class="app-body">
    <main class="d-flex justify-content-center">
        <div class="lookup-card">
            <h3>Your code has expired!</h3>
            <p class="card-text">The passcode you might have recieved for setting up your new Techless account has expired. Please click “Resend Code” to retrieve a new code, or click “Go Back” to try a different email.</p>

            <div class="row mt-3">
                <div class="col-12  mt-3">
                    <button class="back-button curve-border mr-2 " onfocus="blur()" (click)="onBackClick()">Back</button>
                    <button class="submit-button curve-border "  onfocus="blur()" (click)="onResendCodeClick()">Resend Code</button>
                </div>
            </div>
        </div>
    </main>
</div>