import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss']
})
export class NoContentComponent implements OnInit {
  @Input() iconName: string;
  @Input() message: string;
  @Input() styleClass: string;
  constructor() { }

  ngOnInit() {
  }

}
