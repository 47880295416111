import { NgModule } from "@angular/core";
import {
  MatButtonModule,
  MatDialogModule,
  MatCardModule,
  MatDividerModule,
} from "@angular/material";
@NgModule({
  imports: [MatDialogModule, MatButtonModule, MatCardModule, MatDividerModule],
  exports: [MatDialogModule, MatButtonModule, MatCardModule, MatDividerModule],
})
export class AngularMaterialModule {}
