import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';


@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

    title: string
    message: string

    constructor(
        public dialogref: MatDialogRef<AlertComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router) { }

    ngOnInit() {
        this.title = this.data.title;
        this.message = this.data.message;
    }
}
