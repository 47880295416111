import { environment } from './../../../environments/environment';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy,ChangeDetectorRef, ViewRef  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { User } from 'src/app/object/user.object';
declare var $: any;
@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {
    public personData: Array<User> = [];
    public previous: any;
    public previousChild: number;
    public env: any;
    public subMenuIndex = -1;

    navVisible: boolean;

    @Input() public navId: string;
    @Output() onclose = new EventEmitter();
    isOpen: Array<boolean> = [];
    constructor(private router: Router, public apiService: ApiService, public commonService: CommonService, private route: ActivatedRoute, private cdr: ChangeDetectorRef) {
        setTimeout(() => {
            this.getPerson();
            if (this.cdr != undefined) {
                if (this.navVisible) {
                    this.navId = 'sidebar';
                }
                this.cdr.detectChanges();
            }
        }, 250);
        this.navVisible = commonService.isSidebarVisible;
        setTimeout(() => {
            console.log(this.navVisible);
        }, 500);

    }

    ngOnInit() {
        this.env = environment.environmentName;
        console.log(this.navVisible);
    }

    /**
     * @method memberDetail
     * @description this method fetches the person detail
     */
    public memberDetail(member: any, route?: string, index?: number, childMenuIndex?: number) {
        if (member) {
            member.linkIndex = index;
            member.childMenuIndex = childMenuIndex;
        }
        this.commonService.encryptAndSaveData(member, 'person');

    }

    public routeToTechless() {
        window.location.href = 'https://techless.com/store/wisephone';
    }


    /**
     * @method toggleMenu
     * @description this method helps to toggle the menu items in mobile view
     */
    public toggleMenu(goto?: string, close?: string) {
        if (!close) {
            this.onclose.emit('close');

        }
        if (goto) {
            this.router.navigate([goto]);
        }
    }


    /**
     * @method logout
     * @description this method logout the user from the app
     */
    public logout() {
        localStorage.clear();
        // this.router.navigate(['/login']);
        window.location.reload();

    }

    /**
     * @method getPerson
     * @description this method fetches the person detail
     */
    public getPerson() {
        this.apiService.httpGet('persons?requestFrom=1').subscribe((response: any) => {
            if (response && response.data) {
                this.commonService.personData = response.data;
                this.commonService.encryptAndSaveData(this.commonService.personData, 'members');
                console.log("xcvcxv");
                setTimeout(()=>{
                  if (this.cdr && !(this.cdr as ViewRef).destroyed) {
                    this.cdr.detectChanges();
                  }
                }, 500);
            }
        });
    }

    ngOnDestroy() {
        this.commonService.personInfo = new User();
        this.commonService.personData = [];
        this.commonService.errorMessage = '';

    }
    /**
     * @method toggleSubMenus
     * @param index: number
     * @description this method helps to toggle the menu items in mobile view
     */
    public toggleSubMenus(index: number) {
        this.subMenuIndex = index;
    }

}
