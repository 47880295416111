import { CheckRouteGuard } from 'src/app/guards/check-route.guard';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { CreateIdComponent } from './auth/create-id/create-id.component';
import { HomeComponent } from './components/main/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { ChangePinComponent } from './auth/change-pin/change-pin.component';
import { BugsComponent } from './components/main/bugs/bugs.component';
import { OrderComponent } from './components/order/order.component';
import { LookUpOrderComponentComponent } from './components/order/lookup/look-up-order-component.component';
import { OrderVerificationComponentComponent } from './components/order/verify/order-verification-component.component';
import { RegisterUserComponent } from './components/order/register-user/register-user.component';
import { ExpiredComponent } from './components/order/expired-code/expired.component';




const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
//   {
//     path: 'device-card-demo',
//     component: DeviceCardComponent
//   },
//   {
//     path: 'enter-a-code',
//     component: CouponComponent
//   },
  {
    path: 'bugs',
    canActivate: [AuthGuard],
    component: BugsComponent
  },
  {
    path: 'admin',
    component: LoginComponent
  },
  {
    path: 'admin/release-notes',
    component: HomeComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'create-id',
    component: CreateIdComponent
  },
  {
    path: 'app',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [

      {
        path: '',
        redirectTo: 'account',
        pathMatch: 'full'
      },
      {
        path: 'release-notes',
        component: HomeComponent,
        canActivate: [CheckRouteGuard],
      },
      // user
      {
        path: 'person/:id',
        canActivate: [CheckRouteGuard],
        loadChildren: () => import('./components/main/person/person.module').then(mod => mod.PersonModule)
      },

      // account
      {
        path: 'account',
        canActivate: [CheckRouteGuard],
        loadChildren: () => import('./components/main/account/account.module').then(mod => mod.AccountModule)

      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      },
      {
        path: 'change-pin',
        component: ChangePinComponent
      },

    ],
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'new-account',
    component: OrderComponent,
    canActivate: [CheckRouteGuard],
    children: [
        {
        path: 'order-lookup',
        component: LookUpOrderComponentComponent
        },
        {
        path: 'order-verify',
        component: OrderVerificationComponentComponent
        },
        {
        path: 'register',
        component: RegisterUserComponent
        },
        {
        path: 'expired-code',
        component: ExpiredComponent
        }
    ]
  },

//   {
//     path: 'package',
//     component: PackageComponent,
//     canActivate: [CheckRouteGuard],
//     children: [
//       {
//         path: 'plans',
//         component: PricingComponent
//       },
//       {
//         path: 'plans/checkout',
//         component: CheckoutComponent
//       },
//       {
//         path: 'plans/carrier',
//         component: CarrierComponent
//       },
//       {
//         path: 'plans/:id',
//         component: PricingComponent
//       },
//       {
//         path: 'plans/:id/:adId',
//         component: PricingComponent
//       },
//       {
//         path: 'cart',
//         component: CartComponent
//       },
//     ]
//   },
//   {
//     path: 'payments/response',
//     canActivate: [CheckRouteGuard],
//     component: ResponseComponent,
//   },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
