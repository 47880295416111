import { ChangePinComponent } from './auth/change-pin/change-pin.component';
import { CartComponent } from './components/package/cart/cart.component';
import { ApiService } from 'src/app/services/api.service';
import { HomeComponent } from './components/main/home/home.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { LoginComponent } from './auth/login/login.component';
import { AppSharedModule } from './shared/app-shared/app-shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './components/nav/nav.component';
import { MainComponent } from './components/main/main.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ApiInterceptor } from './interceptors/interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonService } from './services/common.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ControlErrorComponent } from './shared/control-error/control-error.component';
import { CreateIdComponent } from './auth/create-id/create-id.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { PricingComponent } from './components/package/pricing/pricing.component';
import { PackageComponent } from './components/package/package.component';
import { ResponseComponent } from './components/payments/response/response.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CalendarModule } from 'primeng/calendar';
import { BugsComponent } from './components/main/bugs/bugs.component';
import { CouponComponent } from './components/package/coupon/coupon.component';
import { DeviceCardComponent } from './components/package/device-card/device-card.component';
import { CheckoutComponent } from './components/package/checkout/checkout.component';
import { CarrierComponent } from './components/package/carrier/carrier.component';
import { LookUpOrderComponentComponent } from './components/order/lookup/look-up-order-component.component';
import { OrderVerificationComponentComponent } from './components/order/verify/order-verification-component.component';
import { ExpiredComponent } from './components/order/expired-code/expired.component';
import { RegisterUserComponent } from './components/order/register-user/register-user.component';
import { OrderComponent } from './components/order/order.component';
// import { SecureCheckoutComponent } from './components/package/secure-checkout/secure-checkout.component';

@NgModule({
    entryComponents: [
        ControlErrorComponent
    ],
    declarations: [
        AppComponent,
        NavComponent,
        MainComponent,
        HomeComponent,
        LoginComponent,
        CreateIdComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        CartComponent,
        PricingComponent,
        PackageComponent,
        ResponseComponent,
        ChangePasswordComponent,
        ChangePinComponent,
        BugsComponent,
        CouponComponent,
        DeviceCardComponent,
        CheckoutComponent,
        CarrierComponent,
        LookUpOrderComponentComponent,
        OrderVerificationComponentComponent,
        ExpiredComponent,
        RegisterUserComponent,
        OrderComponent,
        // SecureCheckoutComponent,
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        AppSharedModule,
        FormsModule,
        BrowserAnimationsModule,
        CalendarModule,
        AppRoutingModule,
        ToastrModule.forRoot(),
        DeviceDetectorModule.forRoot(),
        HttpClientModule,
        NgxSpinnerModule],
    providers: [ApiService, CommonService,
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }],
    bootstrap: [AppComponent]
})
export class AppModule { }

