<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-12 mx-auto menu-page ">
                    <h2>Change Password</h2>
                    <div>
                        <div>
                            <div>
                                <form [formGroup]="form" (ngSubmit)="onUpdate()">
                                    <div class="col-12 mt-5 input-effect">
                                        <input class="effect mt-3" type="password" placeholder=""
                                            formControlName="oldPassword" autocomplete="old-password" />
                                        <label>Old Password</label>

                                        <span class="focus-border"></span>
                                    </div>

                                    <div class="col-12 mt-5 input-effect">
                                        <input class="effect mt-3" [type]="newPassword" placeholder=""
                                            formControlName="newPassword" autocomplete="new-password" />
                                        <label>New Password</label>


                                        <span class="focus-border"></span>
                                    </div>
                                    <div class="col-12 mt-5 input-effect">
                                        <input class="effect mt-3" [type]="confirmPassword" placeholder=""
                                            formControlName="confirmNewPassword" autocomplete="confirm-new-password" />
                                        <label>Confirm New Password</label>

                                        <span class="focus-border"></span>
                                    </div>

                                    <div class="col-12 error"
                                        *ngIf="(notEqual &&      
                                                        form.controls.newPassword.dirty && 
                                                        form.controls.confirmNewPassword.dirty) &&  form.value.confirmNewPassword">
                                        <p class="mt-2 er-text">Passwords Do Not Match</p>
                                    </div>
                                    <div class="col-12 error"
                                        *ngIf="(form.controls.confirmNewPassword.touched||form.controls.confirmNewPassword.dirty) && !form.value.confirmNewPassword ">
                                        <p class="mt-2 er-text">Required</p>

                                    </div>
                                    <div class="col-12 mt-3 t-50">
                                        <div class="row">
                                            <div class="col-6 gutter no-padding">
                                                <button
                                                    class="btn btn-default btn-xl px-4 gutter bnt-label-lg no-padding light-text"
                                                    onfocus="blur()" type="button"
                                                    (click)="commonService.cancelForm(form)">Cancel</button>
                                            </div>
                                            <div class="col-6 align-right-mobile no-padding">
                                                <button
                                                    class="btn btn-default btn-xl px-0 pr-2 gutter bnt-label-lg no-padding"
                                                    onfocus="blur()" type="submit">Submit</button>
                                            </div>
                                            <div class="col-12 align-right-mobile">
                                                <p class="mt-3 mr-2" *ngIf="commonService.errorMessage">
                                                    {{commonService.errorMessage}}</p>
                                                <p class="mt-3 mr-2" *ngIf="submitMsg">{{submitMsg}}</p>

                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        <div class="col-6 mt-4">
                                            <button type="button" (click)="commonService.goBack()"
                                                class="btn btn-default btn-xl px-4" onfocus="blur()">
                                                Back
                                            </button>
                                        </div>
                                        <div class="col-6 mt-4 ">
                                            <button type="button" (click)="onUpdate()" type="submit"
                                                class="btn btn-default btn-xl px-4" onfocus="blur()">
                                                Submit
                                            </button>
                                        </div>
                                    </div> -->
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>