import { CommonService } from 'src/app/services/common.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
    providedIn: 'root'
})
export class CheckRouteGuard implements CanActivate {
    constructor(private commonService: CommonService, private router: Router, private deviceService: DeviceDetectorService) {
    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {

        if (state.url.match('person')) {
            this.commonService.showTopMenu = true;
            this.commonService.personInfo = this.commonService.decryptData('person');
        } else {
            this.commonService.showTopMenu = false;
        }
        /*if (this.deviceService.isMobile() && (state.url.match('package') || state.url.match('payment'))) {
            return false;
        }*/
        return true;
    }

}
