<div class="row" class="account-align">
    <div class="col-12">
        <!--<p class="light-text">Account</p>-->

        <h4 class="title">
            Change PIN
        </h4>

        <div class="row info-align">
            <div class="col-12 col-md-12">
                <form autocomplete="on" [formGroup]="form" (ngSubmit)="submit()">
                    <!-- Email -->
                    <!--<div class="col-12 mt-5 input-effect">
                        <input class="effect mt-3" type="password" placeholder="" formControlName="oldParentPin"
                            maxlength="4" />
                        <label>Old PIN</label>

                        <span class="focus-border"></span>
                    </div>-->
                    <div class="col-12 mt-5 input-effect">
                        <input class="effect mt-3" type="password" placeholder="" formControlName="parentPin"
                            maxlength="4" />
                        <label>New PIN</label>

                        <span class="focus-border"></span>
                    </div>
                    <div class="col-12 mt-5 input-effect">
                        <input class="effect mt-3" type="password" placeholder="" formControlName="confirmPin"
                            maxlength="4" />
                        <label>Retype New PIN</label>
                        <span class="focus-border"></span>
                    </div>
                    <div class="col-12 error"
                        *ngIf="(notEqual &&
                                                        form.controls.parentPin.dirty &&
                                                        form.controls.confirmPin.dirty) && (form.controls.confirmPin.invalid && form.value.confirmPin)">
                        <p class="mt-2 er-text">PIN Mismatch</p>
                    </div>
                    <div class="col-12 mt-3 t-50">
                        <div class="row">
                            <div class="col-6 gutter no-padding">
                                <button class="btn btn-default btn-xl px-4 gutter bnt-label-lg no-padding light-text"
                                    onfocus="blur()" (click)="commonService.cancelForm(form)"
                                    type="button">Cancel</button>
                            </div>
                            <div class="col-6 align-right-mobile no-padding">
                                <button class="btn btn-default btn-xl pr-2 gutter bnt-label-lg no-padding" tabindex="4"
                                    onfocus="blur()" type="submit">Submit</button>

                            </div>
                            <div class="col-12 align-right-mobile">
                                <p class="mt-3 mr-2" *ngIf="commonService.errorMessage">{{commonService.errorMessage}}
                                </p>
                                <p class="mt-3 mr-2" *ngIf="submitMsg">{{submitMsg}}</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
