import { catchError, filter, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { CommonService } from "../services/common.service";

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { NavigationEnd, Router } from "@angular/router";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  public headers: HttpHeaders = new HttpHeaders();
  constructor(public commonService: CommonService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.commonService.errorMessage = "";
    this.commonService.showSpinner();
    const navEndEvent$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      if (e.url.includes("https://d23i6fiot24yw5.cloudfront.net")) {
        this.commonService.hideSpinner();
      }
    });
    this.setHeaders();
    req = req.clone({ headers: this.headers });
    return next
      .handle(req)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (
              event !== null &&
              event.body.error &&
              event.body.error !== 0 &&
              event.body.error !== 4
            ) {
              if (
                event.body.error === 5 ||
                event.body.error === 2 ||
                event.body.error === 6
              ) {
                if (event.body.error === 2) {
                  this.logout();
                } else {
                  return event;
                }
              } else {
                this.actionOnError(event.body.error, event.body.message);
                this.commonService.hideSpinner();
                return event;
              }
            }
            if (this.router.url !== "/app/account") {
              this.commonService.hideSpinner();
            }
          }
          return event;
        })
      )
      .pipe(
        catchError((error) => {
          console.error(error);
          this.commonService.hideSpinner();
          if (error && error.body) {
            this.commonService.showErrorToaster(error.body.message, "");
          }
          return of(error);
        }) as any
      );
  }

  /**
   * @method setHeaders
   * @description this method is used to set the headers
   */
  private setHeaders() {
    const access = localStorage.getItem("access-token");
    this.headers = new HttpHeaders();
    // this.headers = this.headers.append('Accept', 'application/json');

    if (access && access !== "") {
      this.headers = this.headers.append(
        "accessToken",
        this.commonService.decryptedToken
      );
    }
  }

  /**
   * @method actionOnError
   * @description this method used to make necessary action based on error code
   */
  private actionOnError(error: number, message: string) {
    // if (error === 2) {
    //     localStorage.clear();
    //     this.router.navigate(['/login']);
    //     this.commonService.showErrorToaster(message, 'Logout');
    // }
    if (error === 1) {
      this.commonService.errorMessage = message;
      // this.commonService.showErrorToaster(message, 'Error');
    } else if (error === 5) {
      this.commonService.errorMessage = message;
    }
    // this.commonService.showErrorToaster(message, "error");
  }

  private logout() {
    localStorage.clear();
    this.router.navigate(["/login"]);
    this.commonService.showErrorToaster("Session expired", "Logout");
  }
}
