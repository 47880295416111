import { Pattern } from 'src/app/constants/pattern';
import { ApiService } from './../../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { environment } from 'src/environments/environment';
import { encode, decode } from 'js-base64';

@Component({
  selector: 'app-look-up-order-component',
  templateUrl: './look-up-order-component.component.html',
  styleUrls: ['./look-up-order-component.component.scss']
})
export class LookUpOrderComponentComponent implements OnInit {

    public errorMessage = null;
    public form: FormGroup;

  constructor(
    private router: Router,
    private apiService: ApiService,
    public commonService: CommonService,
    private fb: FormBuilder, 
    private route: ActivatedRoute

  ) { }

  ngOnInit() {
    this.form = this.fb.group({
        email: ['', { validators: [Validators.required, Validators.pattern(Pattern.EMAIL_REGEX)] }],
    });
  }

  onEmailSubmit() {
      // Validate Form and send Email //, 
      if (this.form.valid) {
          this.submitEmailService();
      } else {
        this.commonService.validateForm(this.form);
      }
  }

  submitEmailService () {
      const body = this.form.value;
      this.apiService.httpPost('order/submitEmail', body).subscribe((response: any) => {
                
        if (response.error) {
            localStorage.clear();
            this.errorMessage = response.message;
            this.commonService.hideSpinner();
        }

        if (response && !response.error) {
            this.commonService.hideSpinner();
            let email = encode(this.form.value.email);
            localStorage.setItem('orderinfo', email);
            this.router.navigate(['new-account/order-verify']);
        }
    }, err => {
        this.commonService.hideSpinner();
    });

  }

}
