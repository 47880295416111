import { Component, ComponentFactoryResolver, OnInit } from "@angular/core";
import { Pattern } from "src/app/constants/pattern";
import { ApiService } from "./../../../services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { CommonService } from "../../../services/common.service";
import { environment } from "src/environments/environment";
import { encode, decode } from "js-base64";

@Component({
  selector: "app-register-user",
  templateUrl: "./register-user.component.html",
  styleUrls: ["./register-user.component.scss"],
})
export class RegisterUserComponent implements OnInit {
  public errorMessage = null;
  public form: FormGroup;
  public userEmail = null;
  public firstName = null;
  public lastName = null;
  public notEqual = false;
  public showLogin = false;
  public accountExist = false;
  public spinner = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    public commonService: CommonService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.spinner = true;
    this.route.queryParams.subscribe((params) => {
      const decodeData = JSON.parse(decode(params.data));
      this.userEmail = decodeData.email;
      this.firstName = decodeData.firstname;
      this.lastName = decodeData.lastname;
    });
  }

  ngOnInit() {
    this.getAccountExistInfo(this.userEmail);

    this.form = this.fb.group({
      email: [
        this.userEmail,
        new FormControl(
          { value: this.userEmail, disabled: true },
          Validators.required
        ),
      ],
      firstname: [this.firstName, { validators: Validators.required }],
      lastname: [this.lastName, { validators: Validators.required }],
      password: ["", [Validators.required, Validators.minLength(7)]],
      confirmPassword: ["", { validators: Validators.required }],
    });

    this.form.valueChanges.subscribe(() => {
      this.checkValidatity();
    });
  }

  /**
   * @method checkValidatity
   * @description this method checks for password validity
   */
  public checkValidatity(submit?) {
    if (submit) {
      this.form.controls.confirmPassword.markAsDirty();
    }
    if (this.form.value.password !== this.form.value.confirmPassword) {
      this.notEqual = true;
    } else {
      this.notEqual = false;
    }
  }

  onEmailSubmit() {
    setTimeout(() => {
      var usertype = 1;
      if (environment.stageId == 2) {
        usertype = 1;
      } else if (environment.stageId == 3) {
        usertype = 2;
      }
      this.form.patchValue({
        usertype: usertype,
      });
      if (this.form.valid && !this.notEqual) {
        this.commonService.encryptAndSaveData(
          { ...this.form.value },
          "account-id"
        );
        this.registerUser();
      }
    }, 500);
  }

  routeToLogin() {
    this.router.navigate(["/login"]);
  }

  public getAccountExistInfo(email) {
    const body = { email: email };
    this.apiService
      .httpPost(`accountexist`, body)
      .subscribe((response: any) => {
        this.accountExist = response.data;
        this.spinner = false;
      });
  }

  registerUser() {
    this.apiService
      .httpPost("order/register", this.form.value)
      .subscribe((response: any) => {
        if (response) {
          if (response.error === 5 || response.error === 2) {
            this.errorMessage = response.message;
            this.commonService.hideSpinner();
          } else {
            if (response.accessToken) {
              localStorage.removeItem("access-token");
              this.commonService.setLoginData(response.accessToken);
              localStorage.setItem("isCreated", "true");
              if (
                response.data &&
                response.data.firstChildId != null &&
                response.data.firstChildId != ""
              ) {
                if (response.data.firstChildId === -1) {
                  this.router.navigate(["/app/account"]);
                } else {
                  this.router.navigate([
                    `/app/account/welcome/${response.data.firstChildId}`,
                  ]);
                }
              } else {
                this.errorMessage = "Something went wrong! please retry";
              }
            }
            // const accessToken = localStorage.getItem('access-token');

            // if (!accessToken) {
            //     console.log('Access token not found in cache');

            //     this.commonService.setLoginData(response.accessToken);
            //     localStorage.setItem('isCreated', 'true');
            // }
            // console.log('rn, accessToken ===> ', accessToken);

            // console.log("Now ready for rerouting ===>");
            // console.log("first child id ===> ", response.data.firstChildId);

            // this.router.navigate(['/app/account']);
            /*                if(response.data.firstChildId === -1){
                }
                else{
                    this.router.navigate([`/app/account/welcome/${response.data.firstChildId}`]);
                }
                */
          }
          // http://localhost:4200/
        }
      });
  }
}
