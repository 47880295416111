import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-bugs',
    templateUrl: './bugs.component.html',
    styleUrls: ['./bugs.component.scss']
})
export class BugsComponent implements OnInit {

    public bugs = []
    constructor(private apiService: ApiService) { }

    ngOnInit() {
        this.apiService.httpGet('bugs/familyportalbugs').subscribe((response: any) => {
            if (response.message === "success") {
                let inprogress = response.data.filter(a => a.status == "in-progress")
                let backlog = response.data.filter(a => a.status == "backlog")
                let resolved = response.data.filter(a => a.status == "resolved")
                this.bugs = inprogress.concat(backlog, resolved)
            }
        })
    }
}
