<div class="app-body">
  <main class="d-flex justify-content-center">
    <div class="lookup-card">
      <h3>Look Up Order</h3>
      <p class="card-text">Gain immediate access to the Techless Family Portal by verifying your Wisephone order. Please
        enter the email address used when checking out on techless.com.</p>

      <form lass="mt-5" autocomplete="on" [formGroup]="form" (ngSubmit)="onEmailSubmit()">

        <div class="col-12 mt-3 input-effect">
          <input class="effect mt-3" id="email" type="text" placeholder="" formControlName="email" autocomplete="email"
            onkeyup="this.setAttribute('value', this.value);" value="" />
          <label>
            <span>Email</span>
          </label>
          <span class="focus-border"></span>
        </div>
        <div class="row mt-1">
          <div class="col-12  mt-3">
            <button type="submit" class="submit-button" onfocus="blur()">Submit</button>
          </div>
        </div>

        <div class="col-12 mt-3 pl-0 ">
          <p class="error-message">{{this.errorMessage}}</p>
          <br />
        </div>
      </form>
    </div>
  </main>
</div>