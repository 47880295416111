import { ControlErrorsDirective } from 'src/app/directives/controls.directive';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from './../../services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

    public form: FormGroup;
    public notEqual = false;
    public confirmPassword = 'password';
    public newPassword = 'password';
    public submitMsg = ''
    @ViewChild(ControlErrorsDirective, { static: true }) control: ControlErrorsDirective;
    constructor(private fb: FormBuilder,
        public commonService: CommonService,
        private apiService: ApiService) {
        this.submitMsg = '';
    }

    ngOnInit() {
        this.form = this.fb.group({
            oldPassword: ['', { validators: [Validators.required] }],
            newPassword: ['', { validators: [Validators.required] }],
            confirmNewPassword: ['']
        });
        this.form.valueChanges.subscribe(() => {
            this.checkValidatity();
        })
    }

    ngOnDestroy() {
        this.commonService.errorMessage = '';

    }
    /**
     * @method onUpdate
     * @description this method used to update the password
     */
    public onUpdate() {
        this.submitMsg = '';
        this.checkValidatity('s');
        setTimeout(() => {

            if (this.form.valid && !this.notEqual) {
                this.apiService.httpPost('users/changepassword', this.form.value).subscribe((response: any) => {
                    this.submitMsg = response.message;
                });
            } else {
                this.commonService.validateForm(this.form);
            }
        }, 500);
    }

    /**
     * @method toggleType
     * @param key: string
     * @description this method toggles the password type
     */
    public toggleType(key: string) {
        if (this[key] === 'password') {
            this[key] = 'text';
        } else {
            this[key] = 'password';
        }
    }

    /**
     * @method checkValidatity
     * @description this method checks for password validity
     */
    public checkValidatity(submit?) {
        if (submit) {
            this.form.controls.confirmNewPassword.markAsDirty();
        }
        if (this.form.value.newPassword !== this.form.value.confirmNewPassword) {
            this.notEqual = true;
        } else {
            this.notEqual = false;
        }
    }
}
