import { Component, OnInit } from '@angular/core';
import { Pattern } from 'src/app/constants/pattern';
import { ApiService } from './../../../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { environment } from 'src/environments/environment';
import { encode, decode } from 'js-base64';

@Component({
  selector: 'app-expired',
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.scss']
})
export class ExpiredComponent implements OnInit {

    public errorMessage = '';
    private email = null;

  constructor(
    private router: Router,
    private apiService: ApiService,
    public commonService: CommonService,
    private fb: FormBuilder, 
    private route: ActivatedRoute

  ) { }

  ngOnInit() {

    if(localStorage.getItem('orderinfo')) {
        this.email = decode(localStorage.getItem('orderinfo'));
    }
  }

  onBackClick() {
    this.router.navigate(['new-account/order-verify']);
  }


  onResendCodeClick() {
        if(this.email !== null) {
            const postObj = {email: this.email};
            this.apiService.httpPost('order/submitEmail', postObj).subscribe((response: any) => {
                if (response.error) {
                    localStorage.clear();
                    this.commonService.hideSpinner();
                    this.router.navigate(['new-account/order-lookup']);
                }
    
                if (response && !response.error) {
                    this.commonService.hideSpinner();
                    this.router.navigate(['new-account/order-verify']);
                }
            }, err => {
                this.commonService.hideSpinner();
                this.router.navigate(['new-account/order-lookup']);
            });
        } else {
             this.router.navigate(['new-account/order-lookup']);
       }
  }
}
