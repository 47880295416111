import { Pattern } from './../../../constants/pattern';
import { CommonService } from 'src/app/services/common.service';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    public formArray = new FormArray([]);
    public form: FormGroup;
    public showForm = false;
    public releaseNotes = [];
    public note: any;
    public noteTypes = [];
    private appId = 0;
    constructor(private fb: FormBuilder, private apiService: ApiService, public commonService: CommonService, private router: Router) {
        // document.getElementById('content').style.width = '800px'
    }

    ngOnInit() {
        this.form = new FormGroup({
            version: new FormControl('', { validators: [Validators.required, Validators.pattern(Pattern.NUMERIC)], }),
            notes: new FormArray([])
        });
        this.getNoteTypes();
        // this.addNotes();
        this.getReleaseNotes();
    }

    private getNoteTypes() {
        this.apiService.httpGet('apps/noteTypes').subscribe((response: any) => {
            this.noteTypes = response.data;
        });
    }

    /**
     * @method addNotes
     * @description this method add's the notes to the form group
     */
    public addNotes(note?: any) {
        if (note) {
            this.appId = note.appId;
            this.note = note;
        }

        const notes = this.form.get('notes') as FormArray;
        const group = new FormGroup({
            type: new FormControl('', { validators: [Validators.required], }),
            note: new FormControl('', { validators: [Validators.required], })
        });
        notes.push(group);
        this.showForm = true;
    }

    /**
     * @method saveNotes
     * @description this method saves the notes to the form group
     */
    public saveNotes() {
        console.log(this.form.value);
        // this.form.reset();
        setTimeout(() => {

            if (this.form.valid) {
                this.apiService.httpPost('apps/' + this.appId, this.form.value).subscribe((response: any) => {
                    this.commonService.showSuccessToaster(response.message, 'Notes Added');
                    this.getReleaseNotes();
                    this.closeModal();
                });
            }
        }, 500);


    }

    public setNote(note: any) {
        this.note = note;
        console.log(this.note)
    }

    private closeModal() {
        document.getElementById('modalClose').click();
    }
    /**
     * @method clearData
     * @description this method clears the data
     */
    public clearForm() {
        const controls = (this.form.get('notes') as FormArray).controls;
        controls.forEach((control, index) => {
            this.removeNotes(0);

        });
        this.form.reset();
        this.closeModal();
    }
    /**
     * @method logout
     * @description this method logout the user from the app
     */
    public logout() {
        localStorage.clear();
        this.router.navigate(['/admin']);

    }

    /**
     * @method removeNotes
     * @description this method removes the data
     */
    public removeNotes(index: number) {
        const notes = this.form.get('notes') as FormArray;
        notes.removeAt(index);
    }

    /**
     * @method getControls
     * @description this method gets the form array controls
     */
    public getControls() {
        const formArray = (this.form.get('notes') as FormArray).controls;
        return formArray;
    }

    /**
     * @method getReleaseNotes
     * @description this method fetches the previous release notes
     */
    private getReleaseNotes() {
        this.apiService.httpGet('apps').subscribe((response: any) => {
            this.releaseNotes = response.data;
        });
    }
}
