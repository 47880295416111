<div class="col-12 mt-5">
    <div class="col-12">
        <h2 class="title">
            Release Notes
        </h2>
        <h5> <span (click)="logout()" class="pointer">Logout</span>
        </h5>
        <div class="row align-top mt-5">
            <div class="col-12">
                <div class="row align-center">

                    <div class="col-4">
                        <p><b>App</b></p>
                    </div>
                    <div class="col-2">
                        <p><b>Version</b></p>
                    </div>
                    <div class="col-3">
                        <p><b>Updated By</b></p>
                    </div>
                    <div class="col-3" *ngIf="commonService.isDeveloper">
                        <p><b>Action</b></p>

                    </div>
                </div>

            </div>
            <div class="col-12">
                <div class="row align-center" *ngFor="let app of releaseNotes">

                    <div class="col-4 pointer" (click)="setNote(app)">
                        <p>{{app.appName}}</p>
                    </div>
                    <div class="col-2 pointer" (click)="setNote(app)">
                        <p>{{app.version}}</p>
                    </div>
                    <div class="col-3 pointer" (click)="setNote(app)">
                        <p>{{app.updatedBy}}</p>
                    </div>
                    <div class="col-3" *ngIf="commonService.isDeveloper">
                        <p (click)="addNotes(app);" data-toggle="modal" data-target="#addNotes"> Add Note</p>
                    </div>
                </div>

            </div>
            <div class="col-12 mt-5" *ngIf="this.note">
                <h4>{{note.appName}} Release Notes:</h4>

                <!-- <h5><span>App Name: {{note?.appName}}</span> <span *ngIf="note?.version">,Version:
                        {{note?.version}}</span> <span *ngIf="note?.updatedBy">,UpdatedBy: {{note.updatedBy}}</span>
                </h5> -->
                <ul>
                    <li *ngFor="let list of note.notes">
                        <p>[{{list.type}}] {{list.note}}</p>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade " id="addNotes" tabindex="-1" role="dialog" aria-labelledby="addNotes">
    <div class="modal-dialog photo-modal" role="document">
        <div class="modal-content photo-modalcontent">

            <div class="modal-body" style="padding-top: 0px;" *ngIf="showForm">
                <div class="col-12">
                    <h5 class="mt-5">Add Release Notes ({{note.appName}})</h5>
                </div>

                <form autocomplete="on" [formGroup]="form" (ngSubmit)="saveNotes()">
                    <div class="col-12 mt-3">
                        <input class="form-control" formControlName="version" placeholder="Version number">

                    </div>
                    <div class="col-12 mt-3">
                        <h5>Note</h5>
                    </div>
                    <ng-container formArrayName="notes">
                        <!-- {{log(form)}} -->
                        <div *ngFor="let _ of getControls(); index as i">
                            <ng-container [formGroupName]="i">
                                <div class="col-4 float-left zi-1 mt-3">
                                    <select class="form-control" formControlName="type">
                                        <option value="" selected disabled>Choose</option>
                                        <option *ngFor="let type of noteTypes" [value]="type.id">{{type.type}}</option>
                                    </select>
                                </div>
                                <div class="col-8 float-left zi-1  mt-3">
                                    <div class="col-10 float-left">
                                        <textarea formControlName="note" class="form-control"
                                            placeholder="Add notes"></textarea>

                                    </div>
                                    <div class="col-2 float-left" *ngIf="getControls().length > 1">
                                        <span class="ml-3 fa fa-times pointer" (click)="removeNotes(i)"></span>

                                    </div>
                                </div>

                            </ng-container>
                        </div>
                    </ng-container>

                    <div class="col-12">
                        <div class="row pt-5">
                            <div class="col-4">
                                <button class="btn  mr-2 pointer" type="button" (click)="addNotes()">Add</button>
                            </div>
                            <div class="col-8 align-right-mobile">
                                <button class="btn  mr-5 pointer" type="submit">Save</button>
                                <!-- data-dismiss="modal" -->
                                <button class="btn  pointer" type="button" (click)="clearForm()" id="close-modal">
                                    Cancel</button>
                                <span data-dismiss="modal" id="modalClose"></span>
                            </div>
                            <div class="col-12 align-right-mobile no-padding">
                                <p class="mt-3 mr-2" *ngIf="commonService.errorMessage">
                                    {{commonService.errorMessage}}</p>
                                <!-- <p class="mt-3 mr-2" *ngIf="submitMsg">{{submitMsg}}</p> -->

                            </div>
                        </div>


                    </div>
                </form>
            </div>
            <div>
            </div>
        </div>
    </div>
</div>