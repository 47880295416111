import { NoContentComponent } from "./../no-content/no-content.component";
import { BreadcrumbComponent } from "./../breadcrumb/breadcrumb.component";
import { SecureCheckoutComponent } from "../../components/package/secure-checkout/secure-checkout.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxInfiniteScrollerModule } from "ngx-infinite-scroller";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ControlErrorsDirective } from "src/app/directives/controls.directive";
import { FormSubmitDirective } from "src/app/directives/form-submit.directive";
import { ControlErrorComponent } from "../control-error/control-error.component";
import { ControlErrorContainerDirective } from "src/app/directives/control-error-container.directive";
import { QRCodeModule } from "angularx-qrcode";
import { FocusInvalidDirective } from "src/app/directives/focus-invalid.directive";
import { PhoneMaskDirective } from "src/app/directives/phone-mask.directive";
import { AngularMaterialModule } from "src/app/ng-material components/material.module";
import { AlertComponent } from "src/app/ng-material components/alert component/alert.component";
import { PictureModelComponent } from "src/app/ng-material components/photoModel/photo-model.component";

@NgModule({
  declarations: [
    SecureCheckoutComponent,
    BreadcrumbComponent,
    ControlErrorsDirective,
    FocusInvalidDirective,
    FormSubmitDirective,
    PhoneMaskDirective,
    ControlErrorContainerDirective,
    ControlErrorComponent,
    NoContentComponent,
    AlertComponent,
    PictureModelComponent,
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxInfiniteScrollerModule,
    QRCodeModule,
    AngularMaterialModule,
  ],
  exports: [
    SecureCheckoutComponent,
    BreadcrumbComponent,
    NgxInfiniteScrollerModule,
    QRCodeModule,
    FormsModule,
    ReactiveFormsModule,
    ControlErrorsDirective,
    FocusInvalidDirective,
    FormSubmitDirective,
    PhoneMaskDirective,
    NoContentComponent,
    ControlErrorContainerDirective,
    ControlErrorComponent,
    AngularMaterialModule,
  ],

  entryComponents: [AlertComponent, PictureModelComponent],
})
export class AppSharedModule {}
