<!-- <div class="row justify-content-center bg-color-banner py-2">
  <div class="col-11">
    <div class="notice-banner">
      We are performing routine database maintenance that may temporarily
      disrupt syncing of contacts, call logs and message logs. The issue will
      correct itself once updates are complete. Please note that this update may
      not resolve all existing syncing issues. Questions? Email us at
      hi@techless.com
    </div>
  </div>
</div> -->
<!--Dev note - lines 1 and 2 are here because of a font issue, if there is a better way to install the Roboto font please advice. NB -->
<link rel="preconnect" href="https://fonts.gstatic.com" />
<link
  href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap"
  rel="stylesheet"
/>
<div class="wrapper mt-4">
  <!-- Sidebar  -->
  <app-nav [navId]="'sidebar'" class="sideBar"></app-nav>

  <div class="row">
    <div
      class="topmenu-parent"
      *ngIf="commonService.personInfo.deviceId && commonService.showTopMenu"
    >
      <ul class="top-menu" *ngIf="!commonService.hideTopBar">
        <li class="top-menu-list">
          <a>
            <span
              [ngClass]="{ active: commonService.menuName == 'call-history' }"
              class="pointer"
              id="link1"
              [routerLink]="[
                '/app/person',
                commonService?.personInfo.deviceId,
                'call-history'
              ]"
              routerLinkActive="active"
              (click)="
                commonService.menuName = 'call-history';
                commonService.showTopMenu = true
              "
            >Call History</span
            ></a
          >
        </li>
        <li class="top-menu-list">
          <a
          ><span
            [ngClass]="{
                active: commonService.menuName == 'message-history'
              }"
            class="pointer"
            id="link2"
            [routerLink]="[
                '/app/person',
                commonService.personInfo.deviceId,
                'message-history'
              ]"
            routerLinkActive="active"
            (click)="
                commonService.menuName = 'message-history';
                commonService.showTopMenu = true
              "
          >Message History</span
          ></a
          >
        </li>
        <li class="top-menu-list">
          <a>
            <span
              [ngClass]="{ active: commonService.menuName == 'location' }"
              class="pointer"
              id="link3"
              [routerLink]="[
                '/app/person',
                commonService.personInfo.deviceId,
                'location'
              ]"
              routerLinkActive="active"
              (click)="
                commonService.menuName = 'location';
                commonService.showTopMenu = true
              "
            >Location</span
            ></a
          >
        </li>
        <li class="top-menu-list">
          <a
          ><span
            [ngClass]="{ active: commonService.menuName == 'contacts' }"
            class="pointer"
            id="link4"
            [routerLink]="[
                '/app/person',
                commonService.personInfo.deviceId,
                'contacts'
              ]"
            routerLinkActive="active"
            (click)="
                commonService.menuName = 'contacts';
                commonService.showTopMenu = true
              "
          >Contacts</span
          ></a
          >
        </li>
        <!--        <li class="top-menu-list" *ngIf="getLocal()">-->
        <!--          <a-->
        <!--          ><span-->
        <!--            [ngClass]="{ active: commonService.menuName == 'photo' }"-->
        <!--            class="pointer"-->
        <!--            id="link6"-->
        <!--            [routerLink]="[-->
        <!--                '/app/person',-->
        <!--                commonService.personInfo.deviceId,-->
        <!--                'photo'-->
        <!--              ]"-->
        <!--            routerLinkActive="active"-->
        <!--            (click)="-->
        <!--                commonService.menuName = 'photo';-->
        <!--                commonService.showTopMenu = true-->
        <!--              "-->
        <!--          >Photos</span-->
        <!--          ></a-->
        <!--          >-->
        <!--        </li>-->
        <li class="top-menu-list">
          <a
          ><span
            [ngClass]="{ active: commonService.menuName == 'settings' }"
            class="pointer"
            id="link6"
            [routerLink]="[
                '/app/person',
                commonService.personInfo.deviceId,
                'settings'
              ]"
            routerLinkActive="active"
            (click)="
                commonService.menuName = 'settings';
                commonService.showTopMenu = true
              "
          >Settings</span
          ></a
          >
        </li>
        <!-- <li class="top-menu-list">
          <a>
            <span [ngClass]="{'active': commonService.menuName == 'referral-code'}" class="pointer" id="link6"
              routerLink="/app/person/{{this.commonService.personInfo.deviceId}}/referral-code"
              routerLinkActive="active"
              (click)="commonService.menuName = 'referral-code';commonService.showTopMenu=true">Referral
              Code</span>
          </a>
        </li> -->
        <!--<li class="top-menu-list">
                    <a><span [ngClass]="{'active': commonService.menuName == 'support'}" class="pointer" id="link6"
                            [routerLink]="['/app/person',commonService.personInfo.deviceId,'support']"
                            routerLinkActive="active"
                            (click)="commonService.menuName = 'support';commonService.showTopMenu=true">Support</span></a>
                </li>-->
      </ul>
    </div>

    <!-- Page Content  -->
    <div id="content" class="z-index1">
      <div class="col-12 mobile-menu" id="mob-menu">
        <div class="row" id="mob-menu">
          <div class="col-6 mt-4 pl-4">
            <img class="logo" src="logo.png" alt="Techeless" />
          </div>
          <div class="col-6 mt-4">
            <p
              id="sidebarCollapse"
              class="btn text-sidebar align-right-mobile"
              [ngClass]="{ 'light-text': buttonName == 'Close' }"
              (click)="buttonName = buttonName == 'Close' ? 'Menu' : 'Close'"
              onfocus="blur()"
            >
              {{ buttonName }}
            </p>
          </div>
        </div>
        <div
          class="row"
          [ngClass]="{
            show: buttonName == 'Close',
            hide: buttonName == 'Menu'
          }"
        >
          <app-nav [navId]="'topBar'" (onclose)="buttonName = 'Menu'"></app-nav>
        </div>
      </div>

      <div
        class="col-12 t-50"
        [ngClass]="{ show: buttonName == 'Menu', hide: buttonName == 'Close' }"
      >
        <main>
          <router-outlet (activate)="onActivate($event)"></router-outlet>
        </main>
      </div>
    </div>
  </div>
</div>
