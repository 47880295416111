import { Pattern } from 'src/app/constants/pattern';
import { ApiService } from './../../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { encode, decode } from 'js-base64';



@Component({
  selector: 'app-order-verification-component',
  templateUrl: './order-verification-component.component.html',
  styleUrls: ['./order-verification-component.component.scss']
})
export class OrderVerificationComponentComponent implements OnInit {


  public errorMessage = null;
  public form: FormGroup;
  public email = null

  constructor(
    private router: Router,
    private apiService: ApiService,
    public commonService: CommonService,
    private fb: FormBuilder, 
    private route: ActivatedRoute

  ) { }

  ngOnInit() {
    this.form = this.fb.group({
        verificationCode: ['', { validators: Validators.required }],
    });

    if(localStorage.getItem('orderinfo')) {
        this.email = decode(localStorage.getItem('orderinfo'));
    }
  }

  onCodeSubmit() {
      this.commonService.showSpinner();
      if (this.form.valid) {
        this.apiService.httpPost('order/verify/code', this.form.value).subscribe((response: any) => {
            if (response) {
                if (response.error === 2) {
                    this.commonService.hideSpinner();
                    this.router.navigate(['/new-account/expired-code']);
                } else if (response.error === 5) {
                    this.errorMessage = response.message;
                    this.commonService.hideSpinner();
                } else {
                    let queryDataObj = {
                        email: response.data.email.trim(),
                        firstname: response.data.firstname || '',
                        lastname: response.data.lastname || ''
                    };
                    const data = encode(JSON.stringify(queryDataObj));
                    this.router.navigate([`/new-account/register`], { queryParams: {data: data}});
                }

            }
        });
     }
 }

 resendCode() {
     if(this.email !== null) {
        const postObj = {email: this.email};
        this.apiService.httpPost('order/submitEmail', postObj).subscribe((response: any) => {
                    
            if (response.error) {
                localStorage.clear();
                this.errorMessage = response.message;
                this.commonService.hideSpinner();
            }

            if (response && !response.error) {
                this.commonService.hideSpinner();
            }
        }, err => {
            this.commonService.hideSpinner();
            this.router.navigate(['new-account/order-lookup']);
        });
   } else {
    this.router.navigate(['new-account/order-lookup']);
   }
}

}
