import { Pattern } from './../../constants/pattern';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from './../../services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

    public form: FormGroup;
    public clicked = false;
    public submitMsg = '';

    constructor(private fb: FormBuilder,
        private router: Router,
        public commonService: CommonService,
        private apiService: ApiService) {

    }

    ngOnInit() {
        this.form = this.fb.group({
            username: ['', { validators: [Validators.required, Validators.pattern(Pattern.EMAIL_REGEX)] }],
        });
    }

    /**
     * @method onUpdate
     * @description this method used to update the password
     */
    public onSubmit() {
        this.submitMsg = '';

        setTimeout(() => {

            if (this.form.valid) {
                this.apiService.httpPost('users/forgotpassword', this.form.value).subscribe((response: any) => {
                    this.clicked = true;
                    this.submitMsg = response.message;
                    this.commonService.encryptAndSaveData(this.form.value.username, 'password-username');
                    this.router.navigate(['/reset-password']);
                });
            } else {
                this.commonService.validateForm(this.form);
            }
        }, 500);
    }



    ngOnDestroy() {
        this.clicked = false;
        this.commonService.errorMessage = '';
    }
}



