<!-- <link rel="preconnect" href="https://fonts.gstatic.com">
<link
    href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,400;1,900&display=optional"
    rel="stylesheet"> -->
<section>
    <div class="container d-flex flex-column">
        <div class="row my-5 align-items-center justify-content-between">
            <div class="col">
                <h2>Known Issues &amp;&nbsp;Bugs</h2>
            </div>
            <div class="col text-right">
                <a [routerLink]="['/app/account/']" routerLinkActive="active">
                    <h6>Return to Portal</h6>
                </a>
            </div>
        </div>

        <!-- insert content here! -->
        <div *ngFor="let arr of bugs">
            <div class="card {{arr.status}}">
                <div class="card-title">{{arr.title}}</div>
                <div class="card-status"></div>
                <div class="card-body">
                    <div class="card-text">{{arr.description}}</div>
                </div>
            </div>
        </div>
    </div>
</section>