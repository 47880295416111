import { CommonService } from "src/app/services/common.service";
import { Component, OnInit } from "@angular/core";
import {
  RouterOutlet,
  ActivatedRoute,
  Router,
  NavigationEnd,
} from "@angular/router";
import { slideInAnimation } from "./animation";
import { filter } from "rxjs/operators";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  public buttonName = "Menu";
  public menu;
  public is_custom_sdk = false;
  constructor(
    public commonService: CommonService,
    private apiService: ApiService,
    public router: Router
  ) {}

  ngOnInit() {
    // this.menuFunction();
    const navEndEvent$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      if (localStorage.getItem("person")) {
        const device = this.commonService.decryptData("person");
        if (e.url && e.url === `/app/person/${device.deviceId}`) {
          this.apiService
            .httpGet(`devices/${device.deviceId}`)
            .subscribe((response: any) => {
              if (response && response.data) {
                if (response.data.is_custom_sdk_device === 1) {
                  localStorage.setItem("is_custom_sdk", "1");
                } else {
                  if (localStorage.getItem("is_custom_sdk")) {
                    localStorage.removeItem("is_custom_sdk");
                  }
                }
              }
            });
        }
      }
    });
    this.commonService.checkRoute();
  }

  onActivate(event) {
    window.scroll(0, 0);
  }
  getLocal() {
    if (localStorage.getItem("is_custom_sdk")) {
      return true;
    } else {
      return false;
    }
  }
}
