import { style } from "@angular/animations";
import { Pattern } from "src/app/constants/pattern";
import { ApiService } from "./../../services/api.service";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CommonService } from "../../services/common.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  public form: FormGroup;
  public adminLogin = false;
  public email = "";
  public stageId = 1;
  public errorMessage = null;
  constructor(
    private router: Router,
    private apiService: ApiService,
    public commonService: CommonService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.forEach((param) => {
      if (param && param.id) {
        this.email = this.commonService.decrypt(param.id);
      }
    });
    if (this.router.url === "/admin") {
      this.adminLogin = true;
    }
    const token = localStorage.getItem("access-token");
    if (!this.adminLogin && token) {
      this.router.navigate(["app/account"]);
    } else if (this.adminLogin && token) {
      this.router.navigate(["admin/release-notes"]);
    }
  }

  ngOnInit() {
    this.stageId = environment.stageId;
    this.form = this.fb.group({
      username: [
        "",
        {
          validators: [
            Validators.required,
            Validators.pattern(Pattern.EMAIL_REGEX),
          ],
        },
      ],
      password: ["", { validators: Validators.required }],
      usertype: [""],
    });
    document.getElementById("username").addEventListener("focus", () => {
      document.getElementById("user-label").style.top = "0px";
    });
    document.getElementById("password").addEventListener("focus", () => {
      document.getElementById("password-label").style.top = "0px";
    });

    document.getElementById("username").addEventListener("blur", () => {
      if (!this.form.value.username) {
        document.getElementById("user-label").style.top = "20px";
      }
    });
    document.getElementById("password").addEventListener("blur", () => {
      if (!this.form.value.password) {
        document.getElementById("password-label").style.top = "20px";
      }
    });
  }

  /**
   * @method onLoginSubmit
   * @description this method used to validate and submit the user creds to server for authentication
   */
  public onLoginSubmit() {
    setTimeout(() => {
      var usertype = 1;
      if (this.stageId == 2) {
        usertype = 1;
      } else if (this.stageId == 3) {
        usertype = 2;
      }
      this.form.patchValue({
        usertype: usertype,
      });
      if (this.form.valid) {
        this.commonService.showSpinner();
        const body = this.form.value;
        body.requestFrom = 0;
        let url = "";
        url = this.adminLogin ? "admin/login" : "users/login";

        this.apiService.httpPost(url, body).subscribe(
          (response: any) => {
            console.log(response);
            
            if (response.error) {
              localStorage.clear();
              this.router.navigate(["/login"]);
              this.errorMessage = response.message;
              this.commonService.hideSpinner();
            }

            if (response && response.accessToken && !response.error) {
              console.log("refresh token === ", response.refreshToken);
              this.commonService.hideSpinner();
              this.commonService.setLoginData(response.accessToken);
              this.commonService.setRefreshToken(response.refreshToken);
              this.errorMessage = null;
              if (!this.adminLogin) {
                if (!this.email) {
                  this.router.navigate(["app/account"]);
                } else {
                  // let plan = this.commonService.decryptData('plan-detail')
                  // this.router.navigate(['/package/plans/', plan.id])
                  window.location.href = "https://techless.com/store/wisephone";
                }
              } else {
                this.commonService.isDeveloper = response.data.isDeveloper;
                this.router.navigate(["admin/release-notes"]);
              }
            }
          },
          (err) => {
            this.commonService.hideSpinner();
          }
        );
      } else {
        this.commonService.validateForm(this.form);
      }
    }, 500);
  }

  /**
   * @method gotoCreateId
   * @description this method navigates to create id page
   */
  public gotoCreateId() {
    this.router.navigate(["create-id"]);
  }

  public routeToTechless() {
    this.router.navigate(["new-account/order-lookup"]);
  }
}
