import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ApiService } from "src/app/services/api.service";
import { CommonService } from "src/app/services/common.service";
@Component({
  selector: "app-photo-model",
  templateUrl: "./photo-model.component.html",
  styleUrls: ["./photo-model.component.scss"],
})
export class PictureModelComponent implements OnInit {
  public id: string;
  public arr: [any];
  public previewImageUrl;
  public ResizeImage = "dialog-body";
  public pictureArray = [];
  public isDeletedModel = false;
  constructor(
    public dialogref: MatDialogRef<PictureModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    private apiService: ApiService,
  ) {}

  ngOnInit() {
    this.id = this.data.id;
    this.arr = this.data.photos;
    for (let arr of this.data.photos) {
      for (let obj of arr[1]) {
        this.pictureArray.push(obj);
      }
    }
    this.previewImageUrl = this.pictureArray.filter(
      (img) => img.id == this.id
    )[0];
    this.commonService.hideSpinner();
  }

  /**
   * @method nextPicture
   * @description this will call on clear selection of image
   */
  public nextPicture() {
    if (
      this.pictureArray.length - 1 >
      this.pictureArray.indexOf(this.previewImageUrl)
    ) {
      this.previewImageUrl =
        this.pictureArray[this.pictureArray.indexOf(this.previewImageUrl) + 1];
    }
  }

  /**
   * @method prevPicture
   * @description this will call on clear selection of image
   */
  public prevPicture() {
    if (this.pictureArray.indexOf(this.previewImageUrl) > 0) {
      this.previewImageUrl =
        this.pictureArray[this.pictureArray.indexOf(this.previewImageUrl) - 1];
    }
  }
  /**
   * @method getImageSize
   * @description this will calculate image height width and dynamically assign class for portirate and landscape picture
   */
  public getImageSize(url: any) {
    var img = new Image();
    img.src = url;
    this.ResizeImage =
      img.height > img.width ? "dialog-body tall" : "dialog-body";
  }

  /**
   * @method onDelete
   * @description this will call on delete one image
   */
  public onDelete() {
    const body = {
      picIds: [this.previewImageUrl.id],
    };
    this.dialogref.close({ event: "delete", data: body });
  }
  /**
   * @method onDownload
   * @description this will download photos
   */
  public downloadUrl(url: string, fileName: string) {
    this.apiService.downloadFile(url).subscribe((response: any) => {
      const url = URL.createObjectURL(response);
      console.log("url ===>", url);
      const a: any = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.style = "display: none";
      a.click();
      a.remove();
      URL.revokeObjectURL(url);
    });
  }
  public openDialog() {
   this.isDeletedModel = !this.isDeletedModel;
  }
}
