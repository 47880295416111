import {
    Directive,
    OnInit,
    HostListener,
    ElementRef,
    Optional,
    Host
} from '@angular/core';
import { FormSubmitDirective } from './form-submit.directive';
import { Observable, EMPTY, merge } from 'rxjs';
import { NgControl } from '@angular/forms';


@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[formControl], [formControlName]'
})

export class FocusInvalidDirective implements OnInit {
    public submit$: Observable<Event>;

    constructor(
        private el: ElementRef,
        private controlDir: NgControl,
        @Optional() @Host() private form: FormSubmitDirective) {
        this.submit$ = this.form ? this.form.submit$ : EMPTY;
    }
    @HostListener('submit')

    ngOnInit() {
        merge(
            this.submit$,
        ).pipe().subscribe((v) => {
            const controlErrors = this.control.errors;
            if (controlErrors) {
                this.onFormSubmit();
            }
        });

    }
    onFormSubmit() {
        const Control = this.el.nativeElement;
        const invalidControl = this.el.nativeElement.className.includes('ng-invalid');
        if (invalidControl) {
            Control.focus();
        }
    }
    // Getter for getting control
    get control() {
        return this.controlDir.control;
    }

}
