import { style } from '@angular/animations';
import { CommonService } from 'src/app/services/common.service';
import { Component } from '@angular/core';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
import { slideInAnimation } from './components/main/animation';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter } from 'rxjs/operators';

declare var gtag: any;

@Component({
    selector: 'app-root',
    animations: [slideInAnimation],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'familyPortal';

    constructor(public commonService: CommonService, private deviceService: DeviceDetectorService, public router: Router) {
        const isMobile = this.deviceService.isMobile();
        // if (isMobile) {
        //     document.body.style.width = '100% !important'
        // }
        const navEndEvent$ = router.events.pipe(
            filter(e => e instanceof NavigationEnd)
        );
        navEndEvent$.subscribe((e: NavigationEnd) => {
            console.log(e.url);
            if (e.url.includes('add') || e.url.includes('edit')) {
                this.commonService.showTopMenu = false;
            }
            gtag('config', 'AW-671417343', { 'page_path': e.urlAfterRedirects });
        });
    }

    prepareRoute(outlet: RouterOutlet) {
        // tslint:disable-next-line: no-string-literal
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }
    onActivate(event) {
        window.scroll(0, 0);
    }
}
