<div class="app-body">
  <main class="d-flex justify-content-center" *ngIf="spinner === false">
    <div class="lookup-card" *ngIf="!accountExist">
      <h3>Create a Password</h3>

      <form class="mt-1" autocomplete="on" [formGroup]="form" (ngSubmit)="onEmailSubmit()">
        <div class="account-detail mt-3">

          <!-- Email -->
          <div class="col-12">
            <div class="row">
              <div class="col-6  input-effect">
                <input class="effect mt-3" type="text" placeholder="" formControlName="firstname"
                  autocomplete="firstname" />
                <label>First Name</label>

                <span class="focus-border"></span>
              </div>
              <!-- Email -->
              <div class="col-6  input-effect">
                <input class="effect mt-3" type="text" placeholder="" formControlName="lastname"
                  autocomplete="lastname  " />
                <label>Last Name</label>

                <span class="focus-border"></span>
              </div>
            </div>
          </div>

          <!-- Email -->
          <div class="col-12 mt-3 input-effect">
            <input class="effect mt-3 disabled" type="text" placeholder="" formControlName="email" autocomplete="email"
              readonly />
            <label>Email</label>

            <span class="focus-border"></span>
          </div>
          <!-- Password -->
          <div class="col-12 mt-3 input-effect">
            <input class="effect mt-3" type="password" placeholder="" formControlName="password"
              autocomplete="password" />
            <label>Create Password</label>

            <span class="focus-border"></span>
          </div>
          <!-- Confirm Password -->
          <div class="col-12 mt-3 input-effect">
            <input class="effect mt-3" type="password" placeholder="" formControlName="confirmPassword"
              autocomplete="confirmPassword" />
            <label>Confirm Password</label>

            <span class="focus-border"></span>
            <p class="help er-text" *ngIf="(notEqual &&
                        form.controls.password.dirty &&
                        form.controls.confirmPassword.dirty) &&  form.value.confirmPassword">
              Passwords Do Not Match</p>
          </div>

          <div class="row mt-3">
            <div class="col-11  mt-3 d-flex justify-content-center">
              <button type="submit" class="submit-button" onfocus="blur()">Create Password</button>
            </div>
          </div>

          <div class="col-12 mt-3 pl-0 error-msg">
            <p>{{this.errorMessage}}</p>
            <br />
          </div>
        </div>

      </form>


      <div class="mt-0">
        <p class="card-text" style="text-align:center; line-height: 150%;">By creating a Techless account, you agree to
          our Terms of
          Service, Privacy Policy,
          and may be contacted by Techless. We will not sell or share your information.</p>
      </div>
    </div>

    <!-- Already Have an account-->
    <div class="lookup-card" *ngIf="accountExist">
      <div class="mt-0">
        <p class="route-card-text" style="text-align:center; line-height: 150%;">You've already set a password for your
          Family Portal account</p>
      </div>

      <div class="row mt-3">
        <div class="col-11  mt-3 d-flex justify-content-center">
          <button class="route-submit-button" onfocus="blur()" (click)="routeToLogin()">Continue to Family
            Portal</button>
        </div>
      </div>
    </div>
  </main>

  <div class="row" *ngIf="spinner === true">
    <div class="col-12">
      <app-no-content [iconName]="'fa fa-envelope-o fa-5x'" [styleClass]="'t-50'" [message]="commonService.initialMsg">
      </app-no-content>
    </div>
  </div>
</div>