<nav [id]="navId" *ngIf="navId == 'sidebar' || navVisible">
  <div class="sidebar-header pt-3">
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <img class="logo" src="logo-full.png" alt="Techless" />
        </div>

        <div class="col-6">
          <button
            type="button"
            id="sidebarCollapse"
            class="btn text-sidebar bg-turbo-yellow"
            (click)="toggleMenu()"
            onfocus="blur()"
          >
            Close
          </button>
        </div>
      </div>
    </div>

    <div class="col-6">
      <button
        type="button"
        id="sidebarCollapse"
        class="btn text-sidebar bg-turbo-yellow"
        (click)="toggleMenu()"
        onfocus="blur()"
      >
        Close
      </button>
    </div>
  </div>

  <div class="col-12">
    <ul class="list-unstyled components sidebar-nav-list">
      <!-- <li>
                <a><span class="pointer" routerLinkActive="active" [routerLink]="['home']"
                        (click)="commonService.showTopMenu=false;toggleMenu()">Home</span></a>
            </li> -->
      <li *ngFor="let person of commonService.personData; let i = index">
        <div *ngIf="!person.isParent">
          <a *ngIf="!person?.deviceId">
            <span
              class="pointer"
              *ngIf="person?.dob"
              [routerLink]="['/app/account/quick-start', person?.personId]"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: false }"
              (click)="
                memberDetail(person, '', i, 0);
                toggleMenu();
                commonService.personDetail()
              "
            >
              {{ person?.firstname }}
              {{ person?.lastname }}<sup class="stext"></sup
            ></span>
            <!--<span class="pointer" *ngIf="person?.dob && env != 'PROD' "
                            [routerLink]="['/app/account/quick-start', person?.personId]" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                            (click)="memberDetail(person, '', i, 0);toggleMenu();commonService.personDetail()">
                            {{person?.firstname}}
                            {{person?.lastname}}<sup class="stext">PENDING</sup></span>
                        <span class="pointer" *ngIf="person?.dob && env == 'PROD' "
                            [routerLink]="['/app/account/preorder', person?.personId]" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                            (click)="memberDetail(person, '', i, 0);toggleMenu();commonService.personDetail()">
                            {{person?.firstname}}
                            {{person?.lastname}}<sup class="stext">PENDING</sup></span>-->
            <span
              class="pointer"
              *ngIf="!person?.dob"
              [routerLink]="['/app/account/welcome', person?.personId]"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: false }"
              (click)="
                memberDetail(person, '', i, 0);
                toggleMenu();
                commonService.personDetail()
              "
            >
              Setup {{ person?.firstname }} {{ person?.lastname
              }}<sup class="stext">PENDING</sup></span
            >
          </a>
          <a *ngIf="person?.deviceId">
            <span
              class="pointer"
              [routerLink]="['/app/person', person.deviceId]"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: false }"
              (click)="
                memberDetail(person, '', i, 0);
                toggleMenu();
                commonService.personDetail();
                commonService.showTopMenu = true
              "
            >
              {{ person?.firstname }}
            </span></a
          >
        </div>
      </li>
      <li>
        <hr />
      </li>
      <!-- REMOVE ADD <li>
        <a><span class="pointer light-text" (click)="commonService.showTopMenu=false;toggleMenu('/package/plans')">+ Add
            Person</span></a>
      </li> -->
      <li>
        <a
          ><span
            class="pointer"
            [routerLink]="['/app/account/']"
            routerLinkActive="active"
            (click)="commonService.showTopMenu = false; toggleMenu()"
            >Account</span
          ></a
        >
      </li>
      <li>
        <a
          ><span
            class="pointer light-text bugs"
            [routerLink]="['/bugs']"
            routerLinkActive="active"
            >Known Issues &amp; Bugs</span
          ></a
        >
      </li>
      <li>
        <a href="https://techless.com/help/faq" target="_blank"
          ><span class="pointer light-text bugs">FAQ</span></a
        >
      </li>
      <li>
        <a><span class="pointer" (click)="logout()">Log Out</span></a>
      </li>
    </ul>
  </div>
</nav>

<nav [id]="navId" *ngIf="navId == 'topBar'">
  <div class="col-12" id="mobTopBar">
    <ul class="list-unstyled components">
      <!-- <li>
                <a><span class="pointer" routerLinkActive="active" [routerLink]="['home']"
                        (click)="toggleMenu()">Home</span></a>
            </li> -->

      <li *ngFor="let person of commonService.personData; let i = index">
        <div *ngIf="!person.isParent">
          <a *ngIf="!person?.deviceId">
            <span
              class="pointer"
              *ngIf="person?.dob"
              [routerLink]="['/app/account/quick-start', person?.personId]"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: false }"
              (click)="
                memberDetail(person, '', i, 0);
                toggleMenu();
                commonService.personDetail()
              "
            >
              {{ person?.firstname }}
              {{ person?.lastname }}<sup class="stext">PENDING</sup></span
            >
            <!--<span class="pointer" *ngIf="person?.dob && env != 'PROD' "
                            [routerLink]="['/app/account/quick-start', person?.personId]" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                            (click)="memberDetail(person, '', i, 0);toggleMenu();commonService.personDetail()">
                            {{person?.firstname}}
                            {{person?.lastname}}<sup class="stext">PENDING</sup></span>
                        <span class="pointer" *ngIf="person?.dob && env == 'PROD' "
                            [routerLink]="['/app/account/preorder', person?.personId]" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                            (click)="memberDetail(person, '', i, 0);toggleMenu();commonService.personDetail()">
                            {{person?.firstname}}
                            {{person?.lastname}}<sup class="stext">PENDING</sup></span>-->
            <span
              class="pointer"
              *ngIf="!person?.dob"
              [routerLink]="['/app/account/welcome', person?.personId]"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: false }"
              (click)="
                memberDetail(person, '', i, 0);
                toggleMenu();
                commonService.personDetail()
              "
            >
              {{ person?.firstname }}
              {{ person?.lastname }}<sup class="stext">PENDING</sup></span
            >
          </a>
          <a *ngIf="person?.deviceId">
            <span
              class="pointer"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: false }"
              (click)="
                memberDetail(person, '', i, 0);
                toggleMenu('', 'close');
                commonService.personDetail();
                toggleSubMenus(i)
              "
            >
              {{ person?.firstname }}
            </span></a
          >
          <ul
            class="open-close-container list-unstyled submenu"
            id="pageSubmenu{{ i }}"
            *ngIf="person.deviceId && subMenuIndex == i"
          >
            <li>
              <a>
                <span
                  class="pointer"
                  style="font-size: 18px"
                  id="link1"
                  [routerLink]="[
                    '/app/person',
                    person.deviceId,
                    'call-history'
                  ]"
                  routerLinkActive="active"
                  (click)="
                    memberDetail(person, 'call-history', i, 0); toggleMenu()
                  "
                  >Call History</span
                ></a
              >
            </li>
            <li>
              <a
                ><span
                  class="pointer"
                  style="font-size: 18px"
                  id="link2"
                  [routerLink]="[
                    '/app/person',
                    person.deviceId,
                    'message-history'
                  ]"
                  routerLinkActive="active"
                  (click)="
                    memberDetail(person, 'message-history', i, 1); toggleMenu()
                  "
                  >Message History</span
                ></a
              >
            </li>
            <li>
              <a>
                <span
                  class="pointer"
                  style="font-size: 18px"
                  id="link3"
                  [routerLink]="['/app/person', person.deviceId, 'location']"
                  routerLinkActive="active"
                  (click)="memberDetail(person, 'location', i, 2); toggleMenu()"
                  >Location</span
                ></a
              >
            </li>
            <li>
              <a
                ><span
                  class="pointer"
                  style="font-size: 18px"
                  id="link4"
                  [routerLink]="['/app/person', person.deviceId, 'contacts']"
                  routerLinkActive="active"
                  (click)="memberDetail(person, 'contacts', i, 3); toggleMenu()"
                  >Contacts</span
                ></a
              >
            </li>
            <!-- <li>
                <a id="link{{i}}" >Photos</a>
              </li> -->
            <!-- <li>
                            <a><span class="pointer" style="font-size: 18px" id="link5"
                                    [routerLink]="['/app/person',person.deviceId,'photo']" routerLinkActive="active"
                                    (click)="memberDetail(person, 'photo', i, 5);toggleMenu();">Photos</span></a>
                        </li> -->
            <li>
              <a
                ><span
                  class="pointer"
                  style="font-size: 18px"
                  id="link6"
                  [routerLink]="['/app/person', person.deviceId, 'settings']"
                  routerLinkActive="active"
                  (click)="memberDetail(person, 'settings', i, 5); toggleMenu()"
                  >Settings</span
                ></a
              >
            </li>
            <!-- <li>
              <a
                ><span
                  class="pointer"
                  style="font-size: 18px"
                  id="link8"
                  [routerLink]="['/app/person', person.deviceId, 'bonus']"
                  routerLinkActive="active"
                  (click)="memberDetail(person, 'referral', i, 7); toggleMenu()"
                  >Referral Code</span
                ></a
              >
            </li> -->
            <li>
              <a
                ><span
                  class="pointer"
                  style="font-size: 18px"
                  id="link7"
                  [routerLink]="['/app/person', person.deviceId, 'support']"
                  routerLinkActive="active"
                  (click)="memberDetail(person, 'support', i, 6); toggleMenu()"
                  >Support</span
                ></a
              >
            </li>
          </ul>
        </div>
      </li>
      <!-- <li>
              <a ><span class="pointer light-text" [routerLink]="['/app/account/add-person/1']" routerLinkActive="active"  (click)="memberDetail('', 'account'); toggleMenu()" >+ Add Person</span></a>
            </li> -->
      <li class="mt-3">
        <a
          ><span
            class="pointer"
            [routerLink]="['/app/account/']"
            routerLinkActive="active"
            (click)="memberDetail('', 'account'); toggleMenu()"
            >Account</span
          ></a
        >
      </li>
      <!-- <li class="mt-2">
                <a><span class="pointer" [routerLink]="['/app/change-password/']" routerLinkActive="active"
                        (click)="commonService.showTopMenu=false; toggleMenu()">Change Password</span></a>
            </li>
            <li class="mt-2">
                <a><span class="pointer" [routerLink]="['/app/change-pin']" routerLinkActive="active"
                        (click)="commonService.showTopMenu=false; toggleMenu()">Change PIN</span></a>
            </li>-->
      <li>
        <a><span class="pointer" (click)="logout()">Log Out</span></a>
      </li>
    </ul>
  </div>
</nav>
