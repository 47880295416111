import { Pattern } from '../../constants/pattern';
import { CommonService } from '../../services/common.service';
import { ApiService } from '../../services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-change-pin',
    templateUrl: './change-pin.component.html',
    styleUrls: ['./change-pin.component.scss']
})
export class ChangePinComponent implements OnInit {
    public form: FormGroup;
    public notEqual = false;
    public submitMsg = '';

    constructor(private fb: FormBuilder, private apiService: ApiService, public commonService: CommonService) { }

    ngOnInit() {
        this.submitMsg = '';
        this.form = this.fb.group({
            //oldParentPin: ['', { validators: [Validators.required, Validators.pattern(Pattern.NUMERIC), Validators.minLength(4)] }],
            parentPin: ['', { validators: [Validators.required, Validators.pattern(Pattern.NUMERIC), Validators.minLength(4)] }],
            confirmPin: ['', { validators: [Validators.required, Validators.pattern(Pattern.NUMERIC), Validators.minLength(4)], }]
        });

    }



    /**
     * @method submit
     * @description this method submits the form data
     */
    public submit() {
        this.submitMsg = '';
        this.checkValidatity();
        setTimeout(() => {
            if (this.form.valid) {
                const body = {
                    parentPin: this.form.value.parentPin
                };
                this.apiService.httpPost('users/parentpin', body).subscribe((response: any) => {
                    this.submitMsg = response.message;

                });
            }
        }, 500);
    }

    /**
     * @method checkValidatity
     * @description this method checks for password validity
     */
    private checkValidatity() {
        if (!this.form.value.confirmPin) {
            this.form.controls.confirmPin.setErrors({ required: true });

        } else if (this.form.value.parentPin !== this.form.value.confirmPin) {
            this.form.controls.confirmPin.setErrors({ notEqual: true });
            this.notEqual = true;
        } else {
            this.notEqual = false;
            this.form.controls.confirmPin.setErrors(null);
        }
    }
}
