<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container mt-5">
            <div class="row justify-content-center">
                <div class="col-5 mx-auto menu-page " style="top: 25vh;">
                    <h2>Forgot Password</h2>
                    <div>
                        <div>
                            <div>
                                <form autocomplete="on" [formGroup]="form" (ngSubmit)="onSubmit()">

                                    <!-- <p class="text-muted">Sign In to your account</p> -->
                                    <div class="mb-3">
                                        <div class="col-12 mt-5 input-effect">
                                            <input class="effect mt-3" type="text" placeholder=""
                                                formControlName="username" autocomplete="email" />
                                            <label>Registered Email</label>
                                            <span class="focus-border"></span>
                                        </div>
                                        <!-- <div>
                                            <label>Registered Email</label>
                                        </div>
                                        <input type="text" class="form-control" placeholder=""
                                            formControlName="username" autocomplete="email" required /> -->

                                    </div>



                                    <div class="row mt-5">
                                        <div class="col-5 mx-auto">
                                            <button type="button"
                                                class="btn btn-default btn-xl px-4" onfocus="blur()">
                                                Go Back
                                            </button>
                                        </div>
                                        <div class="col-5 mx-auto">
                                            <button type="submit" class="btn btn-default btn-xl px-4" onfocus="blur()">
                                                <span *ngIf="!clicked">Submit</span>
                                                <span *ngIf="clicked">Resend</span>
                                            </button>

                                        </div>
                                        <div class="col-10  mx-auto align-right-mobile">
                                            <p class="mt-3 mr-2" *ngIf="commonService.errorMessage">
                                                {{commonService.errorMessage}}</p>
                                            <p class="mt-3 mr-2" *ngIf="submitMsg">{{submitMsg}}</p>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>